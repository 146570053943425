<template>
    <div class="experiment-audit-list-ctn">
        <div class="experiment-audit-title">
            <img  src="@/assets/exper-audit.png" style="width: 20px;" /> <span style="margin-left: 8px;">实验归档审核</span> 
        </div>
        <div class="experiment-list-options">
            <div class="filter-option">
                <a-input placeholder="实验编号" v-model="experimentNo" allowClear></a-input>
            </div>
            <div class="filter-option">
                <a-input placeholder="申请者" v-model="applyUser" allowClear></a-input>
            </div>
            <div class="filter-option" style="width: 200px;">
                <a-select show-search v-model="projectId" placeholder="项目" style="width: 100%;" allowClear :loading="projectListLoading" @focus="projectSelectFocus" :filter-option="filterOption">
                    <a-select-option v-if="projectListLoading" key="loading" :disabled="true"  >
                         <a-icon type="loading" />     正在加载
                    </a-select-option>
                    <a-select-option v-for="(option, index) in projectList" :key="option.projectId" :value="option.projectId">
                    {{ option.projectName  }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="filter-option" style="width: 200px;">
                <a-range-picker v-model="dateValue" format="YYYY-MM-DD" @change="handleChange" />
            </div>
            <a-button style="margin-left: 40px;" type="primary" @click="handleSearch"> 查询 </a-button>
            <a-button class="reset-btn" @click="resetSearch" > 重置 </a-button>
        </div>
        <div class="experiment-list-ctn">
            <a-table rowKey="experimentId" :columns="columns" :data-source="list" :pagination="false" size="small" class="exper-table"
                :scroll="{ y: tableHeight, x: 1200 }" :loading="listLoading">
                <template slot="experimentNo" slot-scope="text, record, index">
                    <a :href="getExperimentHref(record)" target="_blank" class="experiment-no"> {{ text }} </a>
                </template>
                <template slot="projectName" slot-scope="text, record, index">
                    <a-button type="link" @click="handleProjectDetailClick(record)"> {{ text }} </a-button>
                </template>
                <template slot="auditStatus">
                    <a-tag :color="getExperimentFlowStatuColor(2)">
						{{ getExperimentStatuText(2) }}
					</a-tag>
                </template>
                <template slot="action" slot-scope="text, record, index">
                    <a :href="getExperimentHref(record)" target="_blank" class="experiment-no"> 去审核 </a>
                </template>
            </a-table>
        </div>
        <div class="default-pagination">
            <a-pagination :show-total="total => `共 ${total} 条`"  showSizeChanger @showSizeChange="handleSizeChange" @change="getList" :pageSizeOptions.sync="pageSizeOptions" v-model="current" :pageSize.sync="pageSize" :total="total"></a-pagination>
        </div>
        <ProjectDetailModal :onlyPreview="true" :visible.sync="projectDetailModalVisible" :id="selectedProjectId" :showJump="true">
		</ProjectDetailModal>
    </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import { getMyAuditExperimentList, getMyAuditProjectList } from '@/api/anotherIndex';
import {getExperimentFlowStatuColor,getExperimentStatuText} from '@/utils/enums'
import router from '../../../../router';
import ProjectDetailModal from '@/views/itemCenter/components/projectDetailModal.vue';

const tableHeight = document.documentElement.clientHeight - 380;

export default {
    props: {

    },
    components: {
        ProjectDetailModal
    },
    setup(props, context) {

        const pageSize = ref(15);   // 每页条数

        const pageSizeOptions = ref([ '15', '25', '35', '50']);      // 条数选项

        const current = ref(1);     // 当前页

        const total = ref(0);   // 总条数

        const experimentNo = ref('');   // 筛选实验编号

        const applyUser = ref('');  // 申请人

        const startTime = ref(null);

        const endTime = ref(null);

        const listLoading = ref(false);

        const list = ref([
            
        ]);

        const dateValue = ref([]);

        const columns = ref([
            {
                title: '实验编号',
                dataIndex: 'experimentNo',
                scopedSlots: { customRender: "experimentNo" }
            },
            {
                title: '实验名称',
                dataIndex: 'title',
            },
            {
                title: '归属项目',
                dataIndex: 'projectName',
                scopedSlots: { customRender: "projectName" }
            },
            {
                title: '申请归档时间',
                dataIndex: 'updatedTime',
            },
            {
                title: '申请人',
                dataIndex: 'userName',
            },
            {
                title: '流程状态',
                scopedSlots: { customRender: "auditStatus" }
            },
            {
                title: '操作',
                scopedSlots: { customRender: "action" }
            }
        ])

        const projectId = ref(undefined);

        const projectList = ref([]);

        const projectListInit = ref(false);

        const projectListLoading = ref(false);


        const handleChange = (val) => {
            /*  startTime.value = val.length > 1 ? `${val[0]} 00:00:00` : ''
             endTime.value = val.length > 2 ? `${val[1]} 23:59:59` : '' */
            /* startTime.value = val.length > 0 ? val[0].format('YYYY-MM-DD 00:00:00') : ''
            endTime.value = val.length > 1 ? val[1].format('YYYY-MM-DD 23:59:59') : '' */
            dateValue.value = val;
        }

        const handleSearch = () => {
            current.value = 1;
            getList();
        }

        const getList = async () => {
            listLoading.value = true;
            const res = await getMyAuditExperimentList({
                pageNum: current.value,
                pageSize: pageSize.value,
                userName: applyUser.value,
                startTime: dateValue.value.length > 0 ? dateValue.value[0].format('YYYY-MM-DD 00:00:00') : '',
                endTime: dateValue.value.length > 1 ? dateValue.value[1].format('YYYY-MM-DD 23:59:59') : '',
                experimentNo: experimentNo.value,
                projectId: projectId.value
            })
            if(res.success) {
                total.value = res.data.totalRows;
                list.value = res.data.rows;
                listLoading.value = false;
            }
        }

        onMounted(() => {
            getList()
        })

        const getExperimentHref = (record) => {
            const href = router.resolve({
                name: 'experiment',
				query: { mode: 'preview', id: record.experimentId, projectId: record.projectId }
            })
            return href.href
        }

        const handleSizeChange = () => {
            current.value = 1;
            getList();
        }

        const projectDetailModalVisible = ref(false);   // 项目详情可见变量

        const selectedProjectId = ref(null);

        const handleProjectDetailClick = (record) => {
            selectedProjectId.value = record.projectId;
            projectDetailModalVisible.value = true;
        }

        const getProjectList = async () => {
            projectListLoading.value = true;
            const res = await getMyAuditProjectList();
            if(res.success) {
                projectList.value = res.data;
                projectListLoading.value = false;
                projectListInit.value = true;
                console.log(projectList.value)
            }
        }

        const projectSelectFocus = () => {
            if(!projectListInit.value) {
                getProjectList();
            }
        }

        const filterOption = (input, option) => {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }

        const resetSearch = () => {
            dateValue.value = [];
            current.value = 1;
            applyUser.value = '';
            experimentNo.value = '';
            projectId.value = undefined;
            getList();
        }

        return {
            pageSize,
            pageSizeOptions,
            current,
            experimentNo,
            applyUser,
            startTime,
            endTime,
            listLoading,
            columns,
            total,
            handleChange,
            tableHeight,
            list,
            getList,
            dateValue,
            projectId,
            getExperimentFlowStatuColor,
            getExperimentStatuText,
            handleSearch,
            getExperimentHref,
            handleSizeChange,
            projectList,
            projectListLoading,
            projectListInit,
            projectDetailModalVisible,
            handleProjectDetailClick,
            selectedProjectId,
            getProjectList,
            projectSelectFocus,
            filterOption,
            resetSearch
        }
    }
}

</script>

<style lang="less" scoped>
.experiment-audit-list-ctn {
    background: #fff;
    min-height: calc(100vh - 200px);
    margin: 16px;
    box-shadow: @srims-primary-box-shadow;
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);

    .experiment-audit-title {
        padding-bottom: 16px;
        border-bottom: 1px solid #ebebeb;
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .experiment-list-options {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 16px 0;

        .filter-option+.filter-option {
            margin-left: 24px;
        }
        .reset-btn {
            margin-left: 24px;
			background: #eeeeee;
		}
    }

    .experiment-list-ctn {
        height: calc(100vh - 320px);

        /deep/ .ant-table-thead {
            background: #F0F5FF;
        }

        /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
            margin: 0px;
        }

        /deep/ .ant-table-row {
            td {
                padding: 7px;
            }
        }
        .experiment-no {
            &:visited {
                color: #FF4D4F;
            }
        }
    }
}
</style>
